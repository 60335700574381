import React from "react";
import gif from "../../images/microrec-app/microrec_app.gif";
import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const Pro = () => {
  return (
    <>
      <Grid
        item
        container
        xs={12}
        style={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          padding: "20px",
          backgroundColor: "#202026",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "45%",
          }}
        >
          <h2 style={{ color: "white", textAlign: "center" }}>
            Organize your patient data into sessions
          </h2>{" "}
          <StaticImage
            src="./Session MicroREC app 1.svg"
            alt="White Balance icon"
            loading="lazy"
            placeholder="dominantColor"
            formats={["auto", "webp", "avif"]}
            style={{ maxWidth: "90%", maxHeight: "600px" }}
            imgStyle={{ objectFit: "contain" }}
            quality={70}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={5}
          md={5}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "45%",
          }}
        >
          <img src={gif} style={{ maxWidth: "90%", maxHeight: "600px" }}></img>
          <h2 style={{ color: "white", textAlign: "center", marginTop: "0" }}>
            Create your personalized watermark
          </h2>
        </Grid>
      </Grid>{" "}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5%",
          marginBottom: "5%",
        }}
      >
        <button className="shop">
          <a
            href="http://onelink.to/nqua9j"
            target="_blank"
            style={{ color: "white" }}
          >
            DOWNLOAD NOW
          </a>
        </button>
      </div>
      {/* </Container> */}
    </>
  );
};

export default Pro;
