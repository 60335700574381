import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";

const Download = () => {
  return (
    <Container xs={12} maxWidth={"lg"}>
      <h2 style={{ textAlign: "center", marginBottom: "8%" }}>
        Download the MicroREC App
      </h2>
      <Grid container xs={12} style={{ justifyContent: "center" }}>
        <Grid item sm={5} xs={10}>
          <a
            href="http://onelink.to/nqua9j"
            style={{ display: "flex", justifyContent: "space-around" }}
            target="_blank"
          >
            <button href="http://onelink.to/nqua9j" className="download_button">
              <StaticImage
                src="../../images/GooglePlay.png"
                alt="Google Play icon"
                loading="lazy"
                placeholder="dominantColor"
                formats={["auto", "webp", "avif"]}
                style={{ width: "30px", marginRight: "1em" }}
              />
              <div className="download_text">
                <div style={{ fontSize: "10px" }}>GET&nbsp;IT&nbsp;ON</div>
                <div style={{ fontWeight: "700" }}>Google&nbsp;Play</div>
              </div>
            </button>
          </a>
        </Grid>
        <Grid item sm={5} xs={10} marginTop={{ xs: 3, sm: 0 }}>
          <form
            action="http://onelink.to/nqua9j"
            style={{ display: "flex", justifyContent: "space-around" }}
            target="_blank"
          >
            <button type="submit" className="download_button">
              <StaticImage
                src="../../images/AppStore.png"
                alt="Apple Store icon"
                loading="lazy"
                placeholder="dominantColor"
                formats={["auto", "webp", "avif"]}
                style={{ width: "30px", marginRight: "1em" }}
              />
              <div className="download_text">
                <div style={{ fontSize: "10px" }}>GET&nbsp;IT&nbsp;ON</div>
                <div style={{ fontWeight: "700" }}>App&nbsp;Store</div>
              </div>
            </button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Download;
