import React from "react";
import { Grid } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const Connect = () => {
  return (
    <>
      <Grid item container xs={12} style={{ height: "100vh", width: "100%" }}>
        <div
          style={{
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
          }}
        >
          <StaticImage
            src="./bg.png"
            alt="Background"
            loading="lazy"
            placeholder="dominantColor"
            formats={["auto", "webp", "avif"]}
            quality={70}
            style={{
              width: "100%",
              height: "80%",
              backgroundSize: "cover",
            }}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "bottom",
              width: "100%",
              height: "100%",
            }}
          />
        </div>
        <Grid container xs={12}>
          <Grid
            item
            md={5}
            sm={6}
            xs={12}
            style={{
              zIndex: "10",
              position: "relative",
              color: "white",
            }}
            marginTop={{ xs: 0, md: "2em" }}
            padding={{ xs: "20px 20px 0px 20px", md: "20px 40px 0px 80px" }}
          >
            <h2
              style={{ color: "white", marginTop: "0.5em", fontSize: "37px" }}
            >
              MicroREC Connect{" "}
            </h2>
            <h2 style={{ color: "white" }}>
              Data management<br></br> Made easy
            </h2>
            <div style={{ fontSize: "20px", marginBottom: "3em" }}>
              Your images are kept safe and secure, access them from{" "}
              <b>any device, any time from anywhere.</b>{" "}
            </div>
            <a
              href="/connect"
              target="_blank"
              style={{
                backgroundColor: "white",
                padding: "10px 20px",
                color: "#8F4FFF",
                borderRadius: "20px",
                fontWeight: "600",
              }}
            >
              DISCOVER MORE
            </a>
          </Grid>
          <Grid
            item
            md={7}
            sm={6}
            xs={8}
            style={{ display: "flex", marginLeft: "auto" }}
          >
            <StaticImage
              src="./Buy MicroREC now at customsurgical 2.svg"
              alt="Connect"
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
              style={{
                maxWidth: "100%",
                zIndex: "10",
                position: "relative",
                marginLeft: "auto",
                zIndex: "10",
                display: "flex",
              }}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Connect;
