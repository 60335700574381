import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Recordings from "../components/microrec-app/recordings";
import YoutubeGallery from "../components/microrec-app/gallery";
import Download from "../components/microrec-app/download";
import Seo from "../components/layout/seo";
import Grid from "@mui/material/Grid";
import Subscribe from "../components/misc/check_list";
import Connect from "../components/microrec-app/connect";
import Sessions from "../components/microrec-app/sessions";
import { StaticImage } from "gatsby-plugin-image";

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = (
    <StaticImage
      src="../images/iso_color.png"
      alt="Logo"
      loading="eager"
      formats={["auto", "webp", "avif"]}
    />
  );

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="Digitize your microscope or slit lamp - MicroREC app | Custom Surgical"
        description="Record, optimize, organize, and share your diagnosis or procedures from a slit lamp or microscope in a few clicks."
      />{" "}
      <Subscribe />
      <>
        <div className="image-container">
          <StaticImage
            src="../images/gifs/microrec_hero.png"
            alt="Main Image"
            loading="eager"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            quality={100}
            style={{
              objectFit: "cover",
              aspectRatio: "2 / 1",
              width: "100%",
              height: "auto",
              height: "80vh",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundSize: "cover",
              width: "100%",
            }}
          />{" "}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          ></div>
          <div className="text-overlay">
            <h1 className="text_over_microrec">
              MICROREC APP<div></div>
              <Grid item xs={10} sm={7}>
                <div
                  style={{
                    fontSize: "30px",
                    marginTop: "1em",
                    color: "white",
                    fontWeight: "500",
                  }}
                >
                  <b>Free App</b> to improve your recordings and organize them
                </div>
              </Grid>
              <Grid container xs={12} marginTop={{ xs: 7 }}>
                <Grid item sm={4} xs={6} style={{ marginRight: "1em" }}>
                  <a href="http://onelink.to/nqua9j" target="_blank">
                    <button
                      type="submit"
                      className="download_button"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        border: "solid 1px white",
                      }}
                    >
                      <StaticImage
                        src="../images/microrec-app/GooglePlay_white.png"
                        alt="Google Play icon"
                        loading="eager"
                        placeholder="dominantColor"
                        formats={["auto", "webp", "avif"]}
                        style={{ width: "35px", marginRight: "0.5em" }}
                      />
                      <div className="download_text">
                        <div style={{ fontSize: "12px" }}>
                          GET&nbsp;IT&nbsp;ON
                        </div>
                        <div style={{ fontWeight: "700", fontSize: "20px" }}>
                          Google&nbsp;Play
                        </div>
                      </div>
                    </button>
                  </a>
                </Grid>
                <Grid item sm={4} xs={6} marginTop={{ xs: 3, sm: 0 }}>
                  <form action="http://onelink.to/nqua9j" target="_blank">
                    <button
                      type="submit"
                      className="download_button"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "white",
                        border: "solid 1px white",
                      }}
                    >
                      <StaticImage
                        src="../images/microrec-app/AppStore_white.png"
                        alt="Apple Store icon"
                        loading="eager"
                        placeholder="dominantColor"
                        formats={["auto", "webp", "avif"]}
                        style={{ width: "30px", marginRight: "0.5em" }}
                      />

                      <div className="download_text">
                        <div style={{ fontSize: "12px" }}>
                          GET&nbsp;IT&nbsp;ON
                        </div>
                        <div style={{ fontWeight: "700", fontSize: "20px" }}>
                          App&nbsp;Store
                        </div>
                      </div>
                    </button>
                  </form>
                </Grid>
              </Grid>
            </h1>
          </div>
        </div>
        <Recordings />
        <div style={{ marginBottom: "5%" }}></div>
        <Sessions />
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} margin={0}>
            <YoutubeGallery />
          </Grid>
        </Grid>
        <div style={{ marginBottom: "10%" }} id="Download"></div>
        <Connect />
        <Download />
        <div style={{ marginBottom: "15%" }}></div>
      </>{" "}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
